
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import 'variables';
// sweetalert
@import 'node_modules/sweetalert/dev/sweetalert';
// Bootstrap
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
// Dropzone
@import 'node_modules/dropzone/src/dropzone';
//  Font Awesome
@import 'node_modules/font-awesome/scss/font-awesome';
// flatpickr
@import 'node_modules/flatpickr/dist/flatpickr';
